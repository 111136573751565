<template>
  <component
    :is="currentConfig.component"
    class="multiselect"
    :class="[`multiselect--${currentConfig.name}`, currentConfig.props?.class]"
    v-bind="currentConfig.props"
    v-on="currentConfig.listeners"
    :value="value"
    @input="setValue"
    track-by="id"
    label="option_label"
    :disabled="isDisabled"
  />
</template>

<script>
import multiselect from "vue-multiselect";
import { isJson } from '@shared/components/form-builder/utils';
import '../styles.scss'
import 'vue-multiselect/dist/vue-multiselect.min.css'

const Dropdown = () => import('@shared/components/dropdown-base');

export default {
  props: {
    // value: {
    //   required: true,
    // },
    context: {
      type: Object,
      required: true,
    },
  },
  components: {
    multiselect,
    Dropdown,
  },
  methods: {
  
    setValue(val) {
        // console.log('val :>> ', val);
        // this.value = JSON.stringify(val) || [] // TODO check why did we need this
        this.value = val

    },
  },
  computed: {
    typeConfig() {
      return {
        'default': {
          name: 'default',
          component: multiselect,
          props: {
            options: this.getOptions,
          }
        },
        'alt': {
          name: 'alt',
          component: Dropdown,
          props: {
            multiple: true,
            ...this.context.attributes,
            maxWidth: '100%',
            width: '100%',
            class: ['h9', this.context.classes?.element],
            options: this.getOptions.map((option) => ({
                ...option,
                [this.multiselectLabel]: option.option_label,
                [this.multiselectTrackBy]: option.option_value,
            })),
            showCheckBox: true,
            placeholder: this.placeholder,
            selectedLabel: null,
            loading: this.loading,
            reposition: this.context.attributes.reposition ?? true,
            config: {
              label: 'label',
              trackBy: 'value',
              ...this.multiselectConfig,
            }
          },
          listeners: {
            closed: () => {
              this.$emit('closed');
              this.$emit('blur');
              this.context.blurHandler?.();
            },
          },
        },
      }
    },
    currentConfig() {
      return this.typeConfig[this.context.attributes.variant] || this.typeConfig.default;
    },
    // is used if we have a list of values in model instead of objects
    emitValues() {
      return !!this.context.attributes['emit-values'];
    },
    value: {
        get() {
          const selectedList = isJson(this.context.model) ? JSON.parse(this.context.model) : this.context.model ||  []
          if (this.emitValues) {
          // in this case we have a list of values that we should transform to the appropriate structure (used for multiselect component)
            return selectedList.map((el) => {
              const option = this.getOptions.find(opt => opt.option_value === el)
              if (!option) return undefined
              return {
                ...option,
                [this.multiselectLabel]: option.option_label,
                [this.multiselectTrackBy]: option.option_value,
              }
            })
          } else return selectedList
          // return this.context.model ? JSON.parse(this.context.model) : this.context.model ||  []
        },
        set(val) {
            console.log('val :>> ', val);
            // emitValues is used for multiselect component:
            this.context.model = this.emitValues ? val.map((el) => el[this.multiselectTrackBy]) : val
        }
    },
    getOptions() {
        let options = this.context.options 
        return (options && options.length) ? options.map(ele => ({
          ...ele,
          // use predefined option properties if provided
          option_label: ele[this.multiselectLabel || 'label'],
          option_value: ele[this.multiselectTrackBy || 'value'],
        })) : []
    },
    isDisabled() {
      return this.context?.attributes?.disabled ?? false
    },
    placeholder() {
      return this.context.attributes.placeholder
    },
    multiselectConfig() {
      return this.context.attributes.config;
    },
    multiselectLabel() {
      return this.multiselectConfig?.label || 'label';
    },
    multiselectTrackBy() {
      return this.multiselectConfig?.trackBy || 'value';
    },
    loading() {
      return this.context.attributes.loading ?? false;
    },
  },
  mounted() {
    // console.log('this.context.model :>> ', this.context.model);
    // console.log('this.context :>> ',  [this.model, this.context.model.from] );
  },
};
</script>

<style lang="scss" scoped>
.multiselect {
  &--alt {
    &:not(&.dropdown--custom-styles) {
      padding-inline: 0;
      max-height: unset;
      height: var(--input-height);
      border-radius: var(--border-radius);
      ::v-deep {
        .multiselect {
          font-weight: var(--font-weight);
          &__content-wrapper {
            border-radius: var(--border-radius) !important;
          }
          &__placeholder {
            color: var(--placeholder-color);
          }
        }
      }
    }
    &:not(&.dropdown--reposition) {
      ::v-deep {
        .multiselect {
          &__content-wrapper {
            position: absolute !important;
          }
        }
      }
    }
  }
}
</style>