import Vue from "vue";
import Vuex from "vuex";
import VueCookies from "vue-cookies";
import { AuthStore } from "@/modules/auth/store";
import { FieldsAminStore } from "@/modules/fields-admin/store";
import { PackageStore } from "@/modules/packages/store"
import { CaseStore } from "@/modules/case/store";
import { ProfileStore } from "@/modules/profile/store";
import ThemeStore from "@/modules/theme/store";
import { DashboardStore } from "@/modules/dashboard/store"
import { FormsAdminStore } from "@/modules/form-admin/store"
import { ChecksAdminStore } from "@/modules/checks/store"
import { BlocksAdminStore } from "@/modules/blocks-admin/store"
import { LightRayStore } from "../../../shared/light-ray/store"
import { WorkFlowStore } from "../../../shared/workflow/store"
import { Store } from "../../../shared/store";
import { LoginStore } from "@/pages/login/store";
import axios from "@/axios";

Vue.use(Vuex);

export const RootStore = {
  state: {
    isClient: false,
    isTenant: false,
    tenant_id: null,
    isNavCollapsed: window.localStorage.getItem("nav_collapsed"),
    isEmailNavCollapsed: window.localStorage.getItem("emailnav_collapsed"),
    form_rows: [],
    userPermissions: [],
    isPermission: false,
    user_details: null,
    entityTypeList: [],
  },
  getters: {
    getTenantId: (state) => state.tenant_id,
    getNavCollapsed: (state) => state.isNavCollapsed,
    getEmailNavCollapsed: (state) => state.isEmailNavCollapsed,
    getFormRows: (state) => state.form_rows,
    getUserPermissions: (state) => state.userPermissions.map(el => el.code),
    getIsPermission: (state) => state.isPermission,
    getUserDetails: (state) => state.user_details,
    getIsTenant: (state) => state.isTenant,
    getIsClient: (state) => state.isClient
  },
  mutations: {
    SET_NAV_COLLAPSE(state, payload) {
      state.isNavCollapsed = payload;
    },
    SET_EMAIL_NAV_COLLAPSE(state, payload) {
      state.isEmailNavCollapsed = payload;
    },
    SET_TENANT_ID(state, payload) {
      state.tenant_id = payload;
    },
    SET_USER(state, payload) {
      state.user_details = payload
    },
    SET_FORM_ROWS(state, payload) {
      state.form_rows = payload;
    },
    SET_USER_PERMISSIONS(state, payload) {
     state.userPermissions = payload;
    },
    CHECK_PERMISSION_UPDATED(state, value) {
      state.isPermission = value
    },
    SET_TENANT_TRUE(state) {
      state.isTenant = true;
      state.isClient = false;
    },
    SET_CLIENT_TRUE(state) {
      state.isClient = true;
      state.isTenant = false;
    },
    SET_ENTITY_TYPE_LIST(state, payload) {
      state.entityTypeList = payload;
    },
  },
  actions: {
    setUser({commit} , payload) {
      commit("SET_USER", payload)
    },
    setNavCollapsed({ commit }, payload) {
      window.localStorage.setItem("nav_collapsed", payload);
      commit("SET_NAV_COLLAPSE", payload);
    },
    setEmailNavCollapsed({ commit }, payload) {
      window.localStorage.setItem("emailnav_collapsed", payload);
      commit("SET_EMAIL_NAV_COLLAPSE", payload);
    },
    setTenantId({ commit }, payload) {
      commit("SET_TENANT_ID", payload);
    },
    async fetchTenantId({ commit }) {
      try {
        const { data } = await axios.post(`user-auth/init`, {});
        if (data) {
          VueCookies.set("isClient",true)
          console.log("runninng in tenant init")
          if (data.client_id && data.client_details) {
            commit("SET_CLIENT_TRUE")
            VueCookies.set("isClient",true)
          }
          else {
            commit("SET_TENANT_TRUE")
            VueCookies.set("isClient",false)
          }
          commit("SET_TENANT_ID", data?.tenant_id);
          commit("SET_USER", data.user_details)
        }
      } catch (error) {
        console.log(error.message || `ERROR FETCHING TENANT ID`);
      }
    },
    async fetchFormRows({commit}, payload) {
      try {
          const { data } = await axios.get("/tenant-form", payload);
          // this.rows = data?.data ?? [];
          if (data) {
            commit("SET_FORM_ROWS", data?.data || []);
          }
      } catch (error) {
          console.log("[+] error while fetching tenant-form  :>> ", error);
      }
    },

  
    async fetchPermissions({commit}) {
      try {
        const { data } = await axios(`user/permissions`) 
        if (data) {
          commit("SET_USER_PERMISSIONS", data || [])
          commit("CHECK_PERMISSION_UPDATED", true)
        }
      } catch (error) {
        console.log('error :>> ', error);
      }
    },
  
    async fetchEntityTypeList({commit}) {
      try {
        const { data } = await axios.get(`entity`) 
        if (data) {
          commit("SET_ENTITY_TYPE_LIST", data || [])
        }
      } catch (error) {
        console.log('error :>> ', error);
      }
    }
  },
  modules: {
    auth: {
      ...AuthStore,
    },
    fieldsAdmin: { ...FieldsAminStore },
    CaseStore: { ...CaseStore },
    PackageStore: {...PackageStore},
    formsAdmin: {...FormsAdminStore},
    checksAdmin: {...ChecksAdminStore},
    blocksAdmin: {...BlocksAdminStore},
    ProfileStore: {...ProfileStore},
    DashboardStore: {...DashboardStore},
    ThemeStore: {...ThemeStore},
    LightRayStore: {...LightRayStore},
    WorkFlowStore: {...WorkFlowStore},
    Store: {...Store},
    LoginStore: {...LoginStore},
  },
};

const store = new Vuex.Store(RootStore);

export default store;
