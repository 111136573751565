import Vue from "vue";
import App from "./App.vue";
import "keylines";
// import './style.scss'
import '@shared/assets/styles/style.scss'
// import routes from "./routes";
import store from "./store";
import router from './routes';
import "./plugins/fontawesome"
import "./plugins/index"
import "@/plugins/"
import "@shared/plugins/formulate"
import { directive as onClickOutside } from "@directives/clickoutside.js";
import titleMixin from '@shared/mixins/title'
import "@shared/directives/index.js"
import Section from "@shared/components/ui/Section.vue";
import ContentBox from "@shared/components/ui/ContentBox.vue";

Vue.component('UISection', Section);
Vue.component('UIContentBox', ContentBox);
Vue.mixin(titleMixin)
Vue.config.productionTip = false;
Vue.directive("on-click-outside", onClickOutside);

export const EventBus = new Vue();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
